.blank-card {
  width: 675px;
  height: 900px;
  border-radius: 1px;
  background-color: #ffffff;
}

.logo-Auth {
  padding-top: 5px;
  width: 134px;
  height: 36px;
}
.text-Auth {
  width: 117px;
  height: 24px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e384d;
  position: absolute;
  top: 8px;
  right: 50px;
}
.seConnecterWeegoPro {
  width: 352px;
  height: 32px;
  font-family: Rubik;
  font-size: 34px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #2e384d;
}
.info-Auth {
  width: 378px;
  height: 44px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #8798ad;
}
.form-Auth {
  position: absolute;
}
.second-block {
  padding-top: 200px;
}
.third-block {
  padding-top: 70px;
}
.img {
  background-image: url('../src/assets/img/ville_Auth.jpeg');
  width: 765px;
  height: 100%;
}
