.trip-summary .bus-step-icon svg {
  width: 13px;
  height: 13px;
}

.trip-summary .bus-step-icon svg path:first-child {
  fill: white;
}

.dashed-line {
  display: flex;
  height: 50px;
  width: 2px;
  /* Custom color */
  background-image: repeating-linear-gradient(to bottom, transparent, transparent 5px, rgba(213, 210, 220, 1) 2px, rgba(213, 210, 220, 1) 10px);
  /* Adjust spacing as needed */
  margin-left: 5px;
}