.dashboard-filters-dropdown.ui.dropdown > .text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.dashboard-filters-dropdown.dropdown.item:hover {
  color: initial !important;
}

.dashboard .view-mode-icon.active svg path {
  fill: #f0ecf8;
}
