.trips-registry .ui.table {
  color: rgba(0, 0, 0, 0.6);
}
/* Need the complicated selector to override semantic's styles... */
.trips-registry .ui.labeled.icon.button.driver-vehicle-dropdown {
  padding-right: 40px !important;
}

.trips-registry .ui.dropdown .text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.trips-registry .ui.dropdown.active + .status.icon {
  z-index: 101;
}

.trips-registry .status.ui.header > .image {
  width: auto;
}

.trips-registry .status.red svg path[fill] {
  fill: #e02020;
}

.trips-registry .status.grey svg path[fill] {
  fill: #767676;
}

.trips-registry .status.green svg path[fill] {
  fill: #22d219;
}

.trips-registry .ui.dropdown .menu > .item > .image {
  vertical-align: middle;
}

.trips-registry .ui.dropdown .menu > .item.red.text {
  color: #e02020;
}

.trips-registry .ui.dropdown .menu > .item svg path[fill] {
  fill: #4c4c4c;
}

.trips-registry .ui.dropdown .menu > .item.red.text svg path[fill] {
  fill: #e02020;
}

@media only screen and (max-width: 767px) {
  .trips-registry .table-container {
    height: auto !important;
  }

  .trips-registry .ui.table tr > td {
    width: 250px !important;
  }
}
