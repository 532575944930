@media only screen and (max-width: 767px) {
  .side-menu .sidebar.very.thin {
    width: 0 !important;
  }

  .side-menu .pusher {
    width: 100% !important;
    transform: translate3d(0, 0, 0) !important;
  }
}

.menu .item svg {
  height: 56px;
}

.menu .item.active svg path[fill],
.menu .item.active svg g[fill]:not([fill='none']) {
  fill: white;
}
.menu .item svg path[fill],
.menu .item svg g[fill]:not([fill='none']) {
  fill: white;
}

.menu .item svg path,
.menu .item svg g {
  opacity: 1;
}
