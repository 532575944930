.stop-step:hover {
  background-color: #f9f9f9;
}

.stop-step .stop-actions {
  display: none;
}

.stop-step:hover .stop-actions {
  display: flex;
}
